<template>
  <div class="about" id="about" name="about">
    <div class="header-top">
      <!-- <img src="../../assets/index/bglogin.png"  alt=""> -->
      <div class="header-text">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>设计加工</el-breadcrumb-item>
          <el-breadcrumb-item> <span style="color: #CE3720;">详情</span> </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="header-top-p">
          <div class="header-top-p-l">{{ designInfo.name }}</div>
          <div class="header-top-p-r" @click="goBack()">返回列表</div>
        </div>
      </div>
    </div>
    <div class="main-box ">
      <div class="main-l" v-html="convertPtToPx(designInfo.content)"></div>
      <div class="main-r">
        <div class="tit">热门推荐</div>
        <div class="cp-li" v-for="item in designTj" :key="item.id">
          <div class="cp-img">
            <img :src="$target + item.image" alt="">
          </div>
          <div class="cp-tit">{{ item.name }}</div>
          <div class="cp-info">{{ item.name }}</div>
          <div class="cp-btn" @click="jumpInfo(item)">查看详情</div>
        </div>
        <!-- <div class="cp-li">
          <div class="cp-img">
            <img src="../../assets/index/bg.png" alt="">
          </div>
          <div class="cp-tit">光纤</div>
          <div class="cp-info">光纤三维折射率测试仪SHR-21实现高分辨
率、高精度、无损的测量有源和无源量有源和无量有源和无</div>
          <div class="cp-btn">查看详情</div>
        </div> -->
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>
<script>
import {
  designInfoApi, designRecommendApi
} from "../../api/index.js";
export default {
  components: {
  },
  data() {
    return {
      idx: 1,
      designInfo: {},
      designTj: []
    }
  },
  created() {

  },
  activated() {
    this.id = this.$route.query.id
    this.idx = this.$route.query.idx
    this.getDesignInfo()
    this.getDesignRecommend()
  },
  methods: {
    convertPtToPx(htmlContent) {
      // 使用正则表达式匹配 pt 单位
      const regex = /(\d*\.?\d+)pt/g;

      // 使用 replace 方法替换匹配到的 pt 值
      const convertedContent = htmlContent.replace(regex, function (match, ptValue) {
        // 将 pt 值转换为 px 值，这里使用 1pt ≈ 1.333px
        const pxValue = parseFloat(ptValue) * 0.3528;
        // 返回转换后的 px 值，并保留小数点后两位
        return pxValue.toFixed(2) + "px";
      });

      if (window.innerWidth <= 750) {
        return convertedContent;
      } else {
        return htmlContent;
      }
    },
    getDesignInfo() {
      let data = {
        id: this.id,
      }
      designInfoApi(data).then((res) => {
        this.designInfo = res.data;
      }).catch((res) => {
        this.$message.error(res.message)
      })
    },
    getDesignRecommend() {
      let data = {
        type_id: this.idx,
        status: 2, //默认正常，2获取推荐
      }
      designRecommendApi(data).then((res) => {
        this.designTj = res.data.data;
      }).catch((res) => {
        this.$message.error(res.message)
      })
    },
    goBack() {
      this.$router.back();
    },
    jumpInfo(e) {
      console.log(e);
      this.id = e.id
      this.getDesignInfo()
      this.getDesignRecommend()
      // this.$router.push('/caseInfo')
    },
  },
};
</script>
<style scoped>
.about {
  background-color: #F3F3F3;

}

.about .about-header {
  margin: 0 auto;
  padding-bottom: 100px;
  width: 1250px;
  background-color: #F3F3F3;
}

.about .about-header .about-title {
  padding-top: 20px;
  display: flex;
  margin: 0 auto;
  font-size: 28px;
}

.header-top {
  height: 387px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  background-image: url('../../assets/index/bglogin.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

.header-top img {
  width: 100%;
  height: 100%;
}

.header-top-p {
  padding-top: 59px;
  display: flex;
  justify-content: space-between;
}

.header-text {
  padding-top: 68px;
  max-width: 1250px;
  margin: 0 auto;
}

.header-top-p-l {
  height: 70px;
  font-weight: bold;
  font-size: 30px;
  color: #010000;
  line-height: 70px;
}

.header-top-p-r {
  font-size: 20px;
  color: #2E3443;
  padding-left: 34px;
  background-image: url('../../assets/index/back.png');
  background-repeat: no-repeat;
  background-position: 0 3px;
  background-size: 20px 20px;
  cursor: pointer;
}

.main-box {
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: 0 auto;
}

.main-l {
  padding: 20px;
  box-sizing: border-box;
  width: 74%;
  /* height: 2568px; */
  background: #FFFFFF;
  border-radius: 17px 17px 17px 17px;
}

.main-r {
  padding: 20px 30px;
  box-sizing: border-box;
  width: 24%;
  /* height: 1937px; */
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
}

.tit {
  padding-left: 15px;
  margin-bottom: 20px;
  height: 33px;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  border-left: 5px solid #B50302;
}

.cp-img {
  width: 100%;
  height: 186px;
}

.cp-img img {
  width: 100%;
  height: 100%;
}

.cp-tit {
  padding: 17px 0 27px 0;
  height: 30px;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  line-height: 30px;
}

.cp-info {
  width: 334px;
  font-size: 18px;
  color: #423435;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cp-btn {
  margin-top: 11px;
  padding-bottom: 18px;
  font-size: 18px;
  color: #BA0100;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .main-box {
    max-width: 90%;
  }

  .header-text {
    max-width: 90%;
  }
}
</style>
